<div class="row">
        <div class="text-center add-center displaynone" id="news" style="border: 2px solid red;">
            <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalNewsTopAD"></iframe>
        </div>
        <div class="text-center add-center displaynone" id="fnews" style="border: 2px solid blue;">
            <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalNewsTopAD"></iframe>
        </div>
        <div class="text-center add-center displaynone" id="tnews" style="border: 2px solid green;">
            <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalNewsTopAD"></iframe>
        </div>
    <div class="col-md-9">
        <router-outlet>
        </router-outlet>
    </div>
    <div class="col-md-3">
        <app-spotlight-news></app-spotlight-news>
        <div class="text-center ADdiv" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalNewsADAfterSpotlight"></iframe>
        </div>
        <app-resources-news></app-resources-news>
        <app-events-news></app-events-news>
    </div>
</div>