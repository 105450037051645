<div class="text-center add-center" >
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalResourcesTopAD"></iframe></div>
<router-outlet></router-outlet>
<!-- <div class="row">
    <div class="col-md-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-3">
         <app-square-ad-videos></app-square-ad-videos>
    </div>
</div> -->
