<!-- Modal -->
<div class="modal fade news_main_modal" id="memberSignupModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="memberSignupModalCenterTitle" aria-hidden="true">
    <div class="sign_modal_center1">
      <div class="modal-dialog modal-dialog-centered sign_modal_center" role="document">
        <div class="modal-content news_modal_content">
            <button type="reset" class="close pop-close" data-dismiss="modal" aria-label="Close" (click)="onReset()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <p class="pop-title text-capitalize text-center pop_title_news">Sign up</p>
                    <p class="text-center pop-para">Sign up to contribute and publish your news, events, brand, and
                        content with the community for <b>FREE</b></p>
                    <p class="text-danger">{{Message}}</p>
                    <div>
                        <div class="row">
                            <div class="col-lg-6 form-group FNameBox">
                                <input type="text" formControlName="firstName"
                                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" class="form-control"
                                    id="firstnameSignup" aria-describedby="emailHelp" placeholder="*First Name">

                                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="f.firstName.errors.required">*Required</div>
                                </div>

                            </div>
                            <div class="col-lg-6 form-group LNameBox">
                                <input type="text" formControlName="lastName"
                                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" class="form-control "
                                    id="lastnameSignup" aria-describedby="emailHelp" placeholder="*Last Name">

                                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="f.lastName.errors.required">*Required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control"
                            id="emailSignup" aria-describedby="emailHelp" placeholder="*Email">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">*Required</div>
                            <div *ngIf="f.email.errors.email">*Invalid Email </div>
                        </div>

                    </div>
                    <div>

                        <div class="row">
                            <div class="col-lg-6 form-group NewPassBox">


                                <input type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="passwordSignup"
                                    aria-describedby="emailHelp" placeholder="*Password">


                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">*Required</div>
                                    <div *ngIf="f.password.errors.minlength">*Password must be at least 4 characters
                                    </div>
                                </div>


                            </div>

                            <div class="col-lg-6 form-group ConfPassBox">

                                <input type="password" formControlName="confirmPassword" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                    id="confirmPasswordSignup" aria-describedby="emailHelp"
                                    placeholder="*Confirm Password">

                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">*Required</div>
                                    <div *ngIf="f.confirmPassword.errors.mustMatch">*Passwords must match</div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="form-group">


                        <select formControlName="role" placeholder="I would like to" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                            <option value="" [disabled]="true">I would like to</option>
                            <option value="Publisher">Publish</option>
                            <option value="GuestAuthor">Contribute</option>
                            <option value="Advertise">Advertise</option>
                        </select>
                        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                            <div *ngIf="f.role.errors.required">*Required</div>
                        </div>

                    </div>
                    <!-- <div class="form-check text-center mb-3">
                        <input type="checkbox" formControlName="acceptTerms" id="accepttermsSignup"
                            class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">

                        <label class=" pop-para" for="acceptTerms"> I agree to the 
                            <a target="_blank" routerlink="/terms-and-service" href=""  data-dismiss="modal" aria-label="Close">terms of
                                service</a> and <a target="_blank" routerlink="/privacy-policy" href="" data-dismiss="modal" aria-label="Close">privacy policy</a>.
                            </label>
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">Required</div>
                    </div> -->
                    <div class="form-check text-center mb-3">
                        <input type="checkbox" class="form-check-input" id="accepttermsSignup" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }">
                        <label class=" pop-para" for="acceptTerms"> I agree to the <a href="" data-dismiss="modal" aria-label="Close" routerLink='/terms-and-service'>terms of service</a> and <a href="" data-dismiss="modal" aria-label="Close"
                            routerLink='/privacy-policy'>privacy policy</a>.</label>

                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback text-left">*Required</div>

                    </div>
                    <button class="text-uppercase thm-btn btn-theme-success w-100 pt-3 pb-3"> Submit</button>
                </form>
            </div>
        </div>
      </div>
    </div>
</div>