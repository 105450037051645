<div class="row">
    <div class="text-center add-center" >
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalEventTopAD"></iframe></div>
    <div class="col-md-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-3">
        <app-spotlight-events></app-spotlight-events>
        <div class="text-center ADdiv" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalEventADAfterSpotlight"></iframe></div>
        <app-square-ad-events></app-square-ad-events>
        <app-resources-events></app-resources-events>
    </div>
</div>