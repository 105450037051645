import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-whitepapers',
  templateUrl: './home-whitepapers.component.html',
  styleUrls: ['./home-whitepapers.component.scss']
})

export class HomewhitepapersComponent implements OnInit {

  resDatas: any;
  loaded: boolean;
  envSiteName:any;
  HomeADAfterWhitepaper:any;
  // whitepaperDatas: any;
  // wloaded: boolean;
  // articleDatas: any;
  // aloaded: boolean;

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slideConfigHomeResources = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };

  constructor(private httpclientService: HttpclientService, private sanitizer:DomSanitizer) { 
    this.envSiteName=environment.siteName;
  }

  url1:string='https://'+environment.siteName+'/ADcodeFile/HomeADAfterWhitepaper.html'
  ngOnInit(): void {
    debugger;
    this.interviewData();
    this.HomeADAfterWhitepaper=this.sanitizer.bypassSecurityTrustResourceUrl(this.url1);
    // this.whitepapers();
    // this.articles();
  }
  interviewData(): void {
    debugger;
    this.loaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetHomeResourceWhitepapers?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.resDatas = items;
          console.log("resDatas:",this.resDatas);
          this.loaded = true;
          window.scrollTo(0,0);
        });
  }
  // whitepapers(): void {
  //   this.wloaded = false;
  //   this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetTop5WhitePaperForMobileView?siteName='+this.envSiteName)
  //     .subscribe(
  //       items => {
  //         this.whitepaperDatas = items;
  //         this.wloaded = true;
  //       });
  // }
  // articles(): void {
  //   this.aloaded = false;
  //   this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetTop5ArticleForMobileView?siteName='+this.envSiteName)
  //     .subscribe(
  //       items => {
  //         this.articleDatas = items;
  //         this.aloaded = true;
  //         console.log("articles",this.articleDatas)
  //       });
  // }

}
