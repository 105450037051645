import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  ResourceMetaTagLoaded: boolean;
  ResourceMetaTagData: any;
  envSiteName:any;
  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,private canonical:CanonicalService) 
  { this.envSiteName=environment.siteName;}

  ngOnInit(): void {
  // this.ResourceAllMetaData();
  }

  
  ResourceAllMetaData(): void {
    if(this.ResourceMetaTagLoaded!=true){
    this.ResourceMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'all-resources')
    .subscribe(
      items => {
        this.ResourceMetaTagData = items[0];
        this.ResourceMetaTagLoaded=true;
        console.log('this.ResourceMetaTagData',this.ResourceMetaTagData);


          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.ResourceMetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.ResourceMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.ResourceMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.ResourceMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.ResourceMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.ResourceMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.ResourceMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.ResourceMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }


}
