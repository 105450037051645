import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HttpclientService } from '../../application/httpclient/httpclient.service';
import { environment } from '../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {CanonicalService} from '../../../canonical.service';
import { Location } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FeaturedNewsComponent } from './featured-news/featured-news.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  MetaTagLoaded: boolean;
  MetaTagData: any;
  envSiteName:any;
  GlobalNewsTopAD:SafeResourceUrl;
  GlobalNewsADAfterSpotlight:SafeResourceUrl;
  PageURL:string= window.location.href;
  PageURL2:string ='https://'+environment.siteName+'/news';
  url2:string='https://'+environment.siteName+'/ADcodeFile/GlobalNewsADAfterSpotlight.html';
  url:string='https://'+environment.siteName+'/ADcodeFile/GlobalNewsTopAD.html'

  fnurl:String= "https://"+window.location.hostname+"/news/featured-news";
  tnurl:string = "https://"+window.location.hostname+"/news/trending-news";
  currentpageeurl:string=window.location.href;


  constructor(private httpclientService: HttpclientService,private titleService: Title,private metaTagService: Meta,
    private sanitizer:DomSanitizer,private canonical:CanonicalService,private Location:Location,public routerLink:RouterModule)
   { this.envSiteName=environment.siteName; }

  ngOnInit(): void {
    // this.AllMetaTagNews();
    this.GlobalNewsADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.url2);
    this.GlobalNewsTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.NewsAD();
  }

  NewsAD(): void{

    // alert(this.fnurl);
    if(this.fnurl == window.location.href){
       document.getElementById('fnews').classList.add('displayblock'); 
    }else if(this.tnurl == window.location.href){
      document.getElementById('tnews').classList.add('displayblock'); 

    }else{
      document.getElementById('news').classList.add('displayblock'); 
     }

  }

  AllMetaTagNews(): void {
    if(this.MetaTagLoaded!=true){
    this.MetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'News')
    .subscribe(
      items => {
        this.MetaTagData = items[0];
        this.MetaTagLoaded=true;
        console.log('this.MetaTagData',this.MetaTagData);
        console.log('PagURL',this.PageURL);
        console.log('PagURL2',this.PageURL2);
          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.MetaTagData.PageTitle);

          this.metaTagService.addTag(
            {name:'robots',content:'index,follow'}
          );

          this.metaTagService.addTag(
          { name: 'og:description', content: this.MetaTagData.OgDescription}
          );
         
          this.metaTagService.addTag(
            { name: 'og:title', content: this.MetaTagData.PageTitle}
          );

          this.metaTagService.addTag(
            { name: 'og:image', content: this.MetaTagData.OgImageURL}
          );

          this.metaTagService.addTag(
            { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.addTag(
            { name: 'twitter:description', content: this.MetaTagData.OgDescription}
          );
          this.metaTagService.addTag(
            { name: 'twitter:title', content: this.MetaTagData.PageTitle}
          );
          this.metaTagService.addTag(
            { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }
}
