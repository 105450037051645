import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
// import { environment } from '../../../../../src/environments/environment';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';

@Component({
  selector: 'app-globel-resources',
  templateUrl: './globel-resources.component.html',
  styleUrls: ['./globel-resources.component.scss']
})
export class GlobelResourcesComponent implements OnInit {

  href:any;
  lastString:string;
  envSiteName:any;
  ResourceMetaTagLoaded: boolean;
  ResourceMetaTagData: any;
  GlobalResourcesTopAD:SafeResourceUrl;
  url:string='https://'+environment.siteName+'/ADcodeFile/GlobalResourcesTopAD.html'

  constructor(private httpclientService: HttpclientService,private router:Router,private sanitizer:DomSanitizer,private titleService: Title,private metaTagService: Meta,private canonical:CanonicalService){
    this.envSiteName=environment.siteName; }

  ngOnInit(): void {
    this.GlobalResourcesTopAD=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.ResourceAllMetaData();
  }
  ngOnChanges(): void{
    this.href = this.router.url;
    this.lastString = this.href.substring(this.href.lastIndexOf('/') + 1)
    console.log("this.lastString",this.lastString);
  }

  ResourceAllMetaData(): void {
    if(this.ResourceMetaTagLoaded!=true){
    this.ResourceMetaTagLoaded = false;
    this.httpclientService.getHttpclient('https://api.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'all-resources')
    .subscribe(
      items => {
        this.ResourceMetaTagData = items[0];
        this.ResourceMetaTagLoaded=true;
        console.log('this.ResourceMetaTagData',this.ResourceMetaTagData);


          //seo code for tags
          this.canonical.CreateCanonicalLink();

          this.titleService.setTitle(this.ResourceMetaTagData.PageTitle);

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.ResourceMetaTagData.OgDescription}
          );

          this.metaTagService.updateTag(
            { name: 'og:title', content: this.ResourceMetaTagData.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'og:image', content: this.ResourceMetaTagData.OgImageURL}
          );

          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.ResourceMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.ResourceMetaTagData.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.ResourceMetaTagData.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.ResourceMetaTagData.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
